import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_STOCK_BROKING_E_VOTING_SUCCESS } from "./actionTypes";
import {
  // fetchFeelingOfComplaints,
  // fetchgeneralInformations,
  fetchStockBrokingEvotings,
} from "./api";

import ContactInfoSideBar from "./ContactInfoSideBar";

const documentHost =
  window.documentHost || process.env.REACT_APP_DOCUMENTS_HOST || "";

const useStyles = makeStyles((theme) => ({
  nestList: {
    counterReset: "item",
    display: "inline-block",
  },
  nestListItem: {
    "alignItems": "baseline",
    "&::before": {
      color: theme.palette.primary.main,
      counterIncrement: "item",
      display: "inline-block",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      marginLeft: "-1em",
      width: "1.5em",
    },
  },
  main: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));

const HomeSidebar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { test = [] } = useSelector((state) => state.articles);

  const generalInfomations = useSelector((state) => state.generalInfomations);
  const feelingOfComplaints = useSelector((state) => state.feelingOfComplaints);
  const stockBrokingEvoting = useSelector((state) => state.stockBrokingEvoting);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // FOR GENERAL INFO FETCH //
  // useEffect(() => {
  //   fetchgeneralInformations();
  //   const fetchgeneral = async () => {
  //     try {
  //       dispatch({
  //         type: FETCH_GENERAL_INFOMATIONS_SUCCESS,
  //         generalInfomations: await fetchgeneralInformations(),
  //       });
  //     } catch ({ message }) {
  //       setError(message);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchgeneral();
  // }, [dispatch]);

  // FOR FEELING OF COMPLAINTS //
  // useEffect(() => {
  //   fetchFeelingOfComplaints();
  //   const fetchFeeling = async () => {
  //     try {
  //       dispatch({
  //         type: FETCH_FEELING_SUCCESS,
  //         feelingOfComplaints: await fetchFeelingOfComplaints(),
  //       });
  //     } catch ({ message }) {
  //       setError(message);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchFeeling();
  // }, [dispatch]);

  // FOR FEELING OF StockBrokingEvotings //
  useEffect(() => {
    fetchStockBrokingEvotings();
    const fetchStockBroking = async () => {
      try {
        dispatch({
          type: FETCH_STOCK_BROKING_E_VOTING_SUCCESS,
          stockBrokingEvoting: await fetchStockBrokingEvotings(),
        });
      } catch ({ message }) {
        setError(message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStockBroking();
  }, [dispatch]);

  const SidebarNestedListItem = withStyles({
    root: {
      "&::before": {
        content: `counter(item, upper-alpha) '.'`,
      },
    },
  })(ListItem);
  const SidebarNestedInnerListItem = withStyles({
    root: {
      "&::before": {
        content: `counter(item, lower-roman) '.'`,
      },
    },
  })(ListItem);

  return (
    <>
      <ContactInfoSideBar />
      <Box
        sx={{
          border: "1px solid rgb(152 153 167)",
          borderRadius: 6,
          padding: 20,
          marginTop: 30,
        }}
      >
        {stockBrokingEvoting?.map((data) => {
          // Example HTML content (make sure it's safe and doesn't contain any malicious code)
          const safeHtml = `${data?.attributes?.feelingOfComplaintsOnSCORES}`;

          return (
            <Typography
              color="primary"
              dangerouslySetInnerHTML={{
                __html: safeHtml,
              }}
              key={data?.id}
              style={{ marginBottom: "20px" }}
              variant="subtitle2"
            />
          );
        })}
        <Typography color="primary" variant="subtitle2">
          <Box fontWeight="fontWeightBold">Disclosure of Track Record</Box>
        </Typography>
        <Typography paragraph color="primary" variant="subtitle2">
          <Link href="/trackrecord" underline="always">
            Track Record of Public Issues
          </Link>
        </Typography>
        <Typography color="primary" variant="subtitle2">
          <Box fontWeight="fontWeightBold">General Information Document</Box>
        </Typography>

        {stockBrokingEvoting?.map((data) => (
          <Typography
            color="primary"
            key={data?.id}
            style={{ marginBottom: "20px" }}
            variant="subtitle2"
          >
            <Link
              href={`${documentHost}${data?.attributes?.GeneralInformationDocumentUrl}`}
              underline="always"
            >
              General Information Document
            </Link>
          </Typography>
        ))}

        <Typography color="primary" variant="subtitle2">
          <Box fontWeight="fontWeightBold">Advisory For Investors</Box>
        </Typography>
        <ol type="A">
          <li>
            {stockBrokingEvoting?.map((data, key) => (
              <Typography
                color="primary"
                key={key}
                style={{ marginBottom: "5px" }}
                variant="subtitle2"
              >
                <Link
                  href={`${documentHost}${data?.attributes?.AdvisoryForInvestorsUrl}`}
                  underline="always"
                >
                  Advisory For Investors - NSE and BSE
                </Link>
              </Typography>
            ))}
          </li>
          <br />
          <li>
            <Typography color="primary" variant="subtitle2">
              <Link href="/caution_for_investor" underline="always">
                Caution for Investor
              </Link>
            </Typography>
          </li>
          <br />
          <li>
            <Typography color="primary" variant="subtitle2">
              <Link href="/risk_disclosures" underline="always">
                Risk Disclosures on Derivatives
              </Link>
            </Typography>
          </li>
        </ol>
        <Typography
          color="primary"
          style={{ margin: "20px 0px" }}
          variant="subtitle2"
        >
          <Link href="/investors_awareness" underline="always">
            <Box fontWeight="fontWeightBold">
              For Clients/ Investors - Awareness on Client Due Diligence under
              AML Regulations
            </Box>
          </Link>
        </Typography>

        <Typography color="primary" variant="subtitle2">
          <Box fontWeight="fontWeightBold">
            Investor Charter and Investor Complaints
          </Box>
        </Typography>
        <Typography
          color="primary"
          style={{ marginBottom: "20px" }}
          variant="subtitle2"
        >
          <Link href="/investor_charter" underline="always">
            Investor Charter and Investor Complaints
          </Link>
        </Typography>
        <Typography color="primary" variant="subtitle2">
          <Box fontWeight="fontWeightBold">
            Advisory issued by Exchanges - KYC Compliance
          </Box>
        </Typography>
        {stockBrokingEvoting?.map((data) => (
          <Typography
            color="primary"
            key={data?.id}
            style={{ marginBottom: "20px" }}
            variant="subtitle2"
          >
            <Link
              href={`${documentHost}${data?.attributes?.AdvisoryExchangesKycUrl}`}
              underline="always"
            >
              Advisory issued by Exchanges - KYC Compliance
            </Link>
          </Typography>
        ))}

        <Typography
          color="primary"
          style={{ marginTop: "20px" }}
          variant="subtitle2"
        >
          <Box fontWeight="fontWeightBold">
            Notification on segregation and monitoring of collateral at client
            level
          </Box>
        </Typography>
        <Typography color="primary" variant="subtitle2">
          <Link href="/notification" underline="always">
            Notification on segregation and monitoring of collateral at client
            level
          </Link>
        </Typography>
        <Typography
          color="primary"
          style={{ marginTop: "20px" }}
          variant="subtitle2"
        >
          <Box fontWeight="fontWeightBold">
            Stock Broking E-voting Platforms
          </Box>
        </Typography>
        <ol type="A">
          <li>
            <Link href="https://www.evoting.nsdl.com/" underline="always">
              NSDL
            </Link>
          </li>
          <br />
          <li>
            <Link href="https://www.evotingindia.com/" underline="always">
              CDSL
            </Link>
          </li>
        </ol>
        {/* {stockBrokingEvoting?.map((data) => (
          <Typography
            color="primary"
            variant="subtitle2"
            style={{ marginBottom: "5px" }}
          >
            {data?.id}.
            <Link
              href={`${data?.attributes?.StockBrokingUrl}`}
              underline="always"
            >
              {data?.attributes?.StockBrokingTitle}
            </Link>
          </Typography>
        ))} */}

        <Typography
          color="primary"
          style={{ marginTop: "20px" }}
          variant="subtitle2"
        >
          <Box fontWeight="fontWeightBold">
            <Link href="/client_bank_accounts" underline="always">
              Stock Broking - CLSA India Client Bank Accounts
            </Link>
          </Box>
        </Typography>

        {stockBrokingEvoting?.map((data) => (
          <Typography
            color="primary"
            key={data?.id}
            style={{ marginTop: "20px" }}
            variant="subtitle2"
          >
            <Box fontWeight="fontWeightBold">
              <Link
                href={`${documentHost}${data?.attributes?.KeyManagerialPersonnelUrl}`}
                underline="always"
              >
                Key Managerial Personnel
              </Link>
            </Box>
          </Typography>
        ))}
        <Typography
          color="primary"
          style={{ marginTop: "20px" }}
          variant="subtitle2"
        >
          <Box fontWeight="fontWeightBold">
            <Link href="/account_opening_form" underline="always">
              Account Opening Form
            </Link>
          </Box>
        </Typography>
        <Typography
          color="primary"
          style={{ marginTop: "20px" }}
          variant="subtitle2"
        >
          <Box fontWeight="fontWeightBold">
            <Link
              href="/uploads/Synopsis_of_Standard_Operating_Procedure_for_reporting_the_demise_of_an_investor_for_website_e9162c35b6.pdf"
              underline="always"
            >
              Standard Operating Procedure for reporting the demise of an
              investor
            </Link>
          </Box>
        </Typography>
        <Typography
          color="primary"
          style={{ marginTop: "20px" }}
          variant="subtitle2"
        >
          <Box fontWeight="fontWeightBold">
            <Link
              href="https://investorhelpline.nseindia.com/ClientCollateral/welcomeCLUser"
              underline="always"
            >
              View Client Collateral Details
            </Link>
          </Box>
        </Typography>
        <Typography
          color="primary"
          style={{ marginTop: "20px" }}
          variant="subtitle2"
        >
          <Box fontWeight="fontWeightBold">
            <Link
              href="/uploads/Policy on Handling of Good Till Cancelled Orders (GTC) for Clients -2024.pdf"
              underline="always"
            >
              Handling of Good Till Cancelled Orders (GTC)
            </Link>
          </Box>
        </Typography>
      </Box>

      <Box
        sx={{
          border: "1px solid rgb(152 153 167)",
          borderRadius: 6,
          padding: 20,
          marginTop: 30,
        }}
      >
        <Typography
          color="primary"
          style={{ marginTop: "10px" }}
          variant="subtitle2"
        >
          <Box fontWeight="fontWeightBold">PRO-TRADING DISCLOSURE</Box>
        </Typography>

        <Typography
          color="primary"
          style={{ marginTop: "20px", fontSize: "13px" }}
        >
          Disclosure of Proprietary Trading by CLSA India Private Limited ( the
          Company) pursuant to SEBI circulars no. SEBI/MRD/SE/CIR-42/2003 dated
          November 19, 2003.
          <br />
          In pursuance of the Securities Exchange Board of India (SEBI)
          directives, vide Circulars No. SEBI/MRD/SE/CIR-42/2003 dated November
          19, 2003, we, CLSA India Private Limited hereby inform and intimate to
          our clients that the Company being member of BSE Limited (BSE) and
          National Stock Exchange of India Limited (NSE) is engaging in
          proprietary trading activities in the exchange traded Securities.
          <br />
          The Company in future may also make investment and trade in listed
          debt securities, mutual funds, currency derivatives and other listed
          financial instruments on proprietary basis to meet its collateral and
          margin requirements and carry out other permissible activities as per
          the exchange guidelines.
        </Typography>
      </Box>
    </>
  );
};

export default HomeSidebar;
